import { cx } from "@linaria/core"
import * as RadixToggleButton from "@radix-ui/react-toggle-button"
import React, { ButtonHTMLAttributes, FC } from "react"
import { Span } from "../typograhpy/Span"
import {
  button,
  container,
  innerButton,
  labelContainer,
  parent,
} from "./Switch.styles"
import { InputStatusProp } from "./types"
import { Theme, ThemeSystemProps } from "theme-system"
import { parseAll } from "@config/theme"

export type SwitchProps = Pick<ThemeSystemProps<Theme>, "mb"> &
  InputStatusProp & {
    label?: React.ReactNode
    onChange?: (value: boolean) => void
    toggled?: boolean
    additionalLabel?: React.ReactNode
  } & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onChange">

export const Switch: FC<SwitchProps> = ({
  label,
  className,
  status,
  toggled,
  onChange,
  additionalLabel,
  mb,
}) => {
  return (
    <div className={cx(parent, parseAll({ mb }))}>
      <RadixToggleButton.Root
        className={cx(container, className)}
        onToggledChange={onChange}
        disabled={status === "disabled" ? true : false}
        {...{ toggled }}
      >
        <span className={button} data-switch="">
          <div className={innerButton} data-switch-inner="" />
        </span>
        {label && (
          <div
            className={labelContainer}
            data-has-additional-label={
              typeof additionalLabel !== "undefined" ? "" : undefined
            }
          >
            <label>
              <Span variant="input-label" color="shade400" ml="12">
                {label}
              </Span>
            </label>
          </div>
        )}
      </RadixToggleButton.Root>
      {additionalLabel}
    </div>
  )
}
