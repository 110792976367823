import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rem, rgba } from "polished";
export const container = parse({
  position: "relative",
  display: "flex",
  alignItems: "center"
}, "c1c1cyw0");
export const label = parse({
  ml: "12",
  color: "inherit",
  display: "block"
}, "l1x2c7v2");
export const indicator = parse({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}, "i9rjoya");

require("./Checkbox.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Checkbox.styles.ts");