import { parse } from "@config/theme";
import { css } from "@linaria/core";
import { FC } from "react";
import { IconButton } from "../button/IconButton";
import { Input, InputProps } from "./Input";
export type InputAndSubmitProps = Omit<InputProps, "suffix"> & {
  isSubmitting?: boolean;
};
export const InputAndSubmit: FC<InputAndSubmitProps> = ({
  isSubmitting = false,
  ...rest
}) => {
  return <div className={parent}>
      <Input {...rest} className={input} />
      <div className={button}>
        <IconButton status={isSubmitting ? "loading" : "idle"} variant="secondary" size="extra-small" icon="chevron-right" label="submit" type="submit" />
      </div>
    </div>;
};
const input = "iaakh6f";
const parent = parse({
  position: "relative"
});
const button = parse({
  position: "absolute"
}, "b11e6up");

require("./InputAndSubmit.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./InputAndSubmit.tsx");