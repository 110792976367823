import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconChevronDown: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M25 12l-9 8-9-8"
      ></path>
    </Icon>
  )
}

export default IconChevronDown
