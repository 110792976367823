import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rem } from "polished";
export const inputContainer = parse({
  position: "relative"
}, "if0dc8u");
export const label = parse({
  position: "absolute"
}, "l495bo5");
export const inputStyles = parse({
  pt: "32",
  pb: "16",
  pr: "16",
  pl: "16"
}, "i1u65h4b");
export const suffix = parse({
  position: "absolute",
  color: "shade500"
}, "smhr95m");

require("./Input.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Input.styles.ts");