import { Box } from "@components/Box";
import { Theme } from "@config/theme";
import React, { FC } from "react";
import { ThemeSystemProps } from "theme-system";
import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { Span } from "../typograhpy/Span";
import { rem } from "polished";
export type FieldControlProps = Pick<ThemeSystemProps<Theme>, "mb"> & {
  errorMsg?: string;
  className?: string;
  info?: string;
};
export const FieldControl: FC<FieldControlProps> = ({
  mb = "8",
  errorMsg,
  info,
  children,
  className
}) => {
  return <Box mb={mb} className={className}>
      {children}
      {typeof errorMsg === "string" ? <Span variant="input-label-active" className={errorStyle}>
          {errorMsg}
        </Span> : typeof info === "string" && <Span variant="input-label-active" className={textStyle}>
            {info}
          </Span>}
    </Box>;
};
const textStyle = parse({
  display: "block"
}, "t1ixbhsv");
const errorStyle = parse({
  display: "block"
}, "eohbkw3");

require("./FieldControl.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./FieldControl.tsx");