import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rem } from "polished";
export const textAreaContainer = parse({
  position: "relative"
});
export const label = parse({
  position: "absolute"
}, "l1cdhs2w");
export const textAreaStyles = "t1eiikvw";

require("./TextArea.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./TextArea.styles.ts");