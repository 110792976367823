import * as EmailValidator from "email-validator"

export type ValidationOptions = {
  required?: boolean
  email?: boolean
  password?: boolean
  phone?: boolean
  shouldEqualPassword?: string
  shouldEqual?: string
  date?: boolean
  min?: number
  max?: number
}

const passwordStrengthRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/
const dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/
const phoneRegex =
  /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/

export function validateField(validation: ValidationOptions, label: string) {
  return function (value: any): string | undefined {
    if (!validation) {
      return undefined
    }
    if (validation.required && !value) {
      return `${label} is required`
    }
    if (validation.email && !EmailValidator.validate(value)) {
      return "The email address is not valid"
    }
    if (validation.date && value) {
      if (!dateRegex.test(value)) {
        return `Please enter a valid date in the following format DD-MM-YYYY`
      }
    }
    if (validation.password && value) {
      if (value.length < 8) {
        return `Enter a password of at least 8 characters`
      }
      if (!passwordStrengthRegex.test(value)) {
        return `Enter a stronger password, a password must contain at least a capital letter and a number`
      }
    }
    if (validation.phone && !phoneRegex.test(value)) {
      return "Invalid phone number"
    }
    if (
      validation.shouldEqualPassword &&
      value &&
      value !== validation.shouldEqualPassword
    ) {
      return `Entered passwords are not equal`
    }

    if (validation.shouldEqual && value && value !== validation.shouldEqual) {
      return `Value should equal ${validation.shouldEqual}`
    }

    if (validation.min && value < validation.min) {
      return `Value should be greater than ${validation.min}`
    }
    if (validation.max && value > validation.max) {
      return `Value should be lower than ${validation.min}`
    }
  }
}
