import React, { FC, InputHTMLAttributes } from "react"
import * as styles from "./Radio.styles"
import { InputStatusProp } from "./types"

export type RadioProps = InputHTMLAttributes<HTMLInputElement> &
  InputStatusProp & {
    label: React.ReactNode
    id: string
  }

export const Radio: FC<RadioProps> = ({ label, id, status, ...rest }) => {
  const itemId =
    id ||
    rest.name ||
    `id-${[...Array(5)]
      .map((i) => (~~(Math.random() * 36)).toString(36))
      .join("")}`

  return (
    <button
      className={styles.container}
      data-disabled={status === "disabled" ? "" : undefined}
    >
      <input
        className={styles.radio}
        data-error={status === "error" ? "" : undefined}
        disabled={status === "disabled"}
        type="radio"
        id={itemId}
        {...rest}
      />
      <span className={styles.indicator} data-indicator />
      <label
        className={styles.label}
        htmlFor={id}
        data-error={status === "error" ? "" : undefined}
      >
        {label}
      </label>
    </button>
  )
}

export default Radio
