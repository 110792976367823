import { parse, theme } from "@config/theme";
import { css, cx } from "@linaria/core";
import { rem } from "polished";
import React, { FC, SelectHTMLAttributes } from "react";
import { IconChevronDown } from "../icons/IconChevronDown";
import { baseInput } from "./styles";
import { InputStatus } from "./types";
export type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  status?: InputStatus;
};
export const Select: FC<SelectProps> = ({
  status = "idle",
  label,
  placeholder,
  ...rest
}) => {
  return <div className={container}>
      <select disabled={status === "disabled"} data-status={status} className={cx(baseInput, select)} placeholder={placeholder || label} {...rest} />
      <div className={iconContainer}>
        <IconChevronDown size={16} />
      </div>
    </div>;
};
const container = parse({
  position: "relative"
});
const select = parse({
  px: "16"
}, "se99emx");
const iconContainer = parse({
  position: "absolute",
  display: "flex",
  alignItems: "center"
}, "i1ms3jvo");

require("./Select.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Select.tsx");