import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rem, rgba } from "polished";
export const container = parse({
  position: "relative",
  display: "flex",
  alignItems: "center"
}, "cp8waap");
export const label = parse({
  ml: "12",
  color: "inherit",
  display: "block"
}, "lkemnp5");
export const radio = parse({
  position: "relative"
}, "r14wdzlu");
export const indicator = parse({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}, "icnxcn4");

require("./Radio.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Radio.styles.ts");