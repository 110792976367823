import * as RadixCheckbox from "@radix-ui/react-checkbox"
import React, { FC, InputHTMLAttributes } from "react"
import { IconCheck } from "../icons/IconCheck"
import * as styles from "./Checkbox.styles"
import { InputStatusProp } from "./types"

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> &
  InputStatusProp & {
    label: React.ReactNode
    id: string
    onCheckedChange?: RadixCheckbox.CheckboxProps["onCheckedChange"]
  }

export const Checkbox: FC<CheckboxProps> = ({
  label,
  id,
  checked,
  name,
  onCheckedChange,
  status = "idle",
}) => {
  return (
    <RadixCheckbox.Root
      checked={checked}
      id={id}
      name={name}
      onCheckedChange={onCheckedChange}
      className={styles.container}
      disabled={status === "disabled"}
    >
      <span
        className={styles.indicator}
        data-error={status === "error" ? "" : undefined}
        data-indicator
        data-checked={checked ? "" : undefined}
      >
        <IconCheck size={14} />
      </span>
      <label
        className={styles.label}
        htmlFor={id}
        data-error={status === "error" ? "" : undefined}
        data-disabled={status === "disabled" ? "" : undefined}
      >
        {label}
      </label>
    </RadixCheckbox.Root>
  )
}
