import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rem } from "polished";
export const container = parse({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center"
}, "cuj2yjw");
export const labelContainer = parse({
  display: {
    _: "block",
    medium: "flex"
  },
  justifyContent: "space-between",
  pr: "4",
  textAlign: "left",
  alignItems: "center"
}, "l11gebl4");
export const button = "b1hjztbl";
export const innerButton = parse({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bg: "white"
}, "i1qebcni");
export const parent = parse({
  display: "flex",
  alignItems: "center"
});

require("./Switch.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Switch.styles.ts");